<template>
  <div class="grid mt-1">
    <div id="shipment_form" class="card p-fluid p-inputtext-sm md:col-6">
      <h5 style="font-weight:bold;">{{ $t('shipment.form_create')}}</h5>
      <div class="field grid">
        <label for="agentCode" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.agentCode')}}</label>
        <form-field :message="errors.agentCode" class="col-12 md:col-9">
          <Dropdown id="agentCode" 
              v-model="entity.agentCode" 
              @change="onChangeAgentCode"
              :options="partners" 
              optionLabel="label" 
              optionValue="name" 
              :placeholder="$t('shipment.selectPartner')" />
        </form-field>
      </div>
      <div class="field grid">
        <label for="shipmentCode" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.code')}}</label>
        <form-field :message="errors.shipmentCode" class="col-12 md:col-9">
          <IconField ref="shipmentCode" iconPosition="right">
            <InputText id="shipmentCode" type="text" disabled v-model="entity.shipmentCode"
                style="font-weight: bold; font-size: 14px;" />
              <InputIcon @click="onGetNewShipmentCode" :disabled="!!entity.shipmentCode">
                <i class="pi pi-refresh" />
              </InputIcon>
          </IconField>
        </form-field>
      </div>
      <div class="field grid">
        <label for="referenceCode" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.referenceCode')}}</label>
        <form-field :message="errors.referenceCode" class="col-12 md:col-9">
          <InputText ref="referenceCode" id="referenceCode" v-model.trim="entity.referenceCode"  autofocus :required="false" style="text-transform: uppercase;"/>
        </form-field>
      </div>
      <div class="field grid">
        <label for="awbCode" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.awbCode')}}</label>
        <form-field :message="errors.awbCode" class="col-12 md:col-9">
          <InputMask id="awbCode" ref="awbCode" v-model="entity.awbCode" mask="999-99999999" placeholder="xxx-xxxxxxxx" />
          <!-- <InputText ref="awbCode" id="awbCode" v-model.trim="entity.awbCode"  autofocus :required="false"/> -->
        </form-field>
      </div>
      <div class="field grid">
        <label for="weightAwbKg" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.weightAwbKg')}}</label>
        <form-field :message="errors.weightAwbKg" class="col-12 md:col-9">
          <!-- <InputMask id="weightAwbKg" ref="weightAwbKg" v-model="entity.weightAwbKg" mask="9999" placeholder="xxxx" /> -->
          <InputNumber ref="weightAwbKg" id="weightAwbKg" 
            v-model="entity.weightAwbKg"
            :min="0" :max="9999" fluid 
            :required="!!entity.awbCode" />
        </form-field>
      </div>
       <div class="field grid">
        <label for="packageNumber" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.packageNumber')}}</label>
        <form-field :message="errors.packageNumber" class="col-12 md:col-9">
          <InputNumber ref="packageNumber" id="packageNumber" v-model="entity.packageNumber"
            :max="999" :min="0" :required="!!entity.awbCode" />
        </form-field>
      </div>
       <div class="field grid">
        <label for="packageType" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.packageType')}}</label>
        <form-field :message="errors.packageType" class="col-12 md:col-9">
          <Dropdown id="packageType" 
              v-model="entity.packageType" 
              :options="packageTypes" 
              :placeholder="$t('shipment.selectPackageType')" />
        </form-field>
      </div>
      <div class="field grid">
        <label for="customGate" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.customGate')}}</label>
        <form-field :message="errors.customGate" class="col-12 md:col-9">
          <Dropdown id="customGate" 
              v-model="entity.customGate" 
              :options="customGates" 
              optionLabel="label" 
              optionValue="name" 
              :placeholder="$t('shipment.selectCustomGate')" />
        </form-field>
      </div>
      <div class="field grid">
        <label for="departure" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.departure')}}</label>
        <FormField :message="errors.departure" class="col-12 md:col-9">
          <Calendar v-model="entity.departure" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" :required="true"/>
        </FormField>
      </div>
      <div class="field grid">
        <label for="arrival" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.arrival')}}</label>
        <FormField :message="errors.arrival" class="col-12 md:col-9">
          <Calendar v-model="entity.arrival" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" :required="true"/>
        </FormField>
      </div>
      <!-- <div class="field grid">
        <label for="weightLbs" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('shipment.weightLbs')}}</label>
        <form-field :message="errors.weightLbs" class="col-12 md:col-10">
          <InputNumber ref="weightLbs" id="weightLbs" v-model.trim="entity.weightLbs" :required="entity.awbCode !== null" autofocus />
        </form-field>
      </div> -->
      <div class="field grid">
        <label for="note" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.note')}}</label>
        <div class="col-12 md:col-9">
          <Textarea id="note" v-model="entity.note" required="false" rows="5" cols="20" />
        </div>
      </div>
      <div class="field grid">
        <div class="col-3"/>
        <Toolbar class="col-9 my-2" style="border:0px; padding:0rem 0rem 0rem 0.5rem">
          <template #start>
            <Button :label="$t('button.save')" icon="pi pi-save" @click="onSave($event)"/>
            <i class="mr-2" />
            <!-- <Button :label="$t('button.reset')" icon="pi pi-replay" @click="onReset($event)"/>
            <i class="mr-2" /> -->
          </template>
        </Toolbar>
      </div>  
   </div> 
  </div>
</template>
<script>
//import Form from '@/components/form/Form.vue';
import FormField from '@/components/form/FormField';
//import AutoCompleteValue from '@/components/form/AutoCompleteValue';
import PartnerService from "@/service/PartnerService";
import ShipmentService from "@/service/ShipmentService";
import mixin_form from '@/mixins/form';
import adjustment from "@/utils/adjustment";
import { converter } from "@/utils/common";
export default {
  components: {FormField},
  mixins:[mixin_form],
  created(){
    
  },
  data() {
    const customGates = ["TCS", "EMS", "Ngoại quan", "Hà Nội", "Đà Nẵng"]
    return {
      submitted: false,
      packageCodes: [],
      packageTypes: ["Kiện","Pallet"],
      receiptCodes: [],
      shipmentCodes: [],
      partners: [],
      customGates: customGates.map(gate => ({
        name: gate,
        label: gate
      })),
      entity: {},
      errors: {},
    }
  },
  mounted() {

   PartnerService.getAll().then(res => {
        this.partners = res.filter(item => item.partnerType == 'agent').map(partner => ({
          name: partner.code,
          label: partner.code
        }));
      });
  },
  methods: {
    async onChangeAgentCode(event) {
      const response = await ShipmentService.getNextShipmentCode(event.value);
      this.entity.shipmentCode = response.data;
    },
    async onGetNewShipmentCode() {
      if (this.entity.agentCode) {
        const response = await ShipmentService.getNextShipmentCode(this.entity.agentCode);
        this.entity.shipmentCode = response.data;
      }
    },
    async onSave() {   
      if (this.entity.referenceCode) {
        this.entity.referenceCode = this.entity.referenceCode.toUpperCase();
      }
      if(!this.submitted) {
        this.errors = this.validate(this.entity, 'shipment');
        if(Object.keys(this.errors).length == 0) {
          this.submitted = true;
          if (this.entity.weightKg !== null) {
            this.entity.weightLbs = converter.tolbs(this.entity.weightKg);
          }
          try {
            const response = await ShipmentService.create(this.entity)
            if (response.errorCode == 0) {
              this.entity = adjustment.adjustShipment(response.data);
              this.$toast.add({ severity: 'success', summary: 'Successful', detail: this.$t("shipment.createSuccessfully"), life: 3000 });
              // this.$router.push({ name: 'agent_shipment_edit', params: {id: this.entity.id} });
              this.$router.push({ name: 'agent_shipment_list' });
            } else { 
              this.$toast.add({ severity: 'error', summary: 'Error', detail: this.$t(response.message, this.entity), life: 3000 });
            }
          } catch (e) { 
            console.log(e);
          }
        }
      }
    }
  }
}
</script>
